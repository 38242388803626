var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gl-token-selector',{attrs:{"state":_vm.validationState,"dropdown-items":_vm.users,"loading":_vm.loading,"allow-user-defined-tokens":_vm.emailIsValid,"hide-dropdown-with-no-items":_vm.hideDropdownWithNoItems,"placeholder":_vm.placeholderText,"aria-labelledby":_vm.ariaLabelledby,"text-input-attrs":/* eslint-disable @gitlab/vue-no-new-non-primitive-in-template */ {
    'data-testid': 'members-token-select-input',
    'data-qa-selector': 'members_token_select_input',
  } /* eslint-enable @gitlab/vue-no-new-non-primitive-in-template */},on:{"blur":_vm.handleBlur,"text-input":_vm.handleTextInput,"input":_vm.handleInput,"focus":_vm.handleFocus,"token-remove":_vm.handleTokenRemove},scopedSlots:_vm._u([{key:"token-content",fn:function(ref){
  var token = ref.token;
return [(_vm.validationState === false)?_c('gl-icon',{staticClass:"gl-mr-2",attrs:{"name":"error","size":16}}):(token.avatar_url)?_c('gl-avatar',{attrs:{"src":token.avatar_url,"size":16}}):_vm._e(),_vm._v("\n    "+_vm._s(token.name)+"\n  ")]}},{key:"dropdown-item-content",fn:function(ref){
  var dropdownItem = ref.dropdownItem;
return [_c('gl-avatar-labeled',{attrs:{"src":dropdownItem.avatar_url,"size":32,"label":dropdownItem.name,"sub-label":dropdownItem.username}})]}},{key:"user-defined-token-content",fn:function(ref){
  var email = ref.inputText;
return [_c('gl-sprintf',{attrs:{"message":_vm.$options.i18n.inviteTextMessage},scopedSlots:_vm._u([{key:"email",fn:function(){return [_c('span',[_vm._v(_vm._s(email))])]},proxy:true}],null,true)})]}}]),model:{value:(_vm.selectedTokens),callback:function ($$v) {_vm.selectedTokens=$$v},expression:"selectedTokens"}})}
var staticRenderFns = []

export { render, staticRenderFns }